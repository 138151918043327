/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { InputDeviceInterface } from '../../../call/model/input-device-interface';
import { OutputDeviceInterface } from '../../../call/model/output-device-interface';
import { Store } from '@ngrx/store';
import { MediaService } from '../../../call/services/media.service';
import { AlertService } from '../../../core/audio/alert.service';
import { IrrService } from '../../../core/audio/irr.service';
import { getUserMediaSuccess } from '../../../call/+state/media.actions';
import { effect, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectUsbAlertOutputToHeadsets, selectUsbIrrOutputToHeadsets } from '../../+state/usb.selectors';
import { selectHeadsetAlertingEnabled } from '../../../call/+state/call.selectors';

@Injectable({
    providedIn: 'root'
})
export class RtiAudioService {
    public static readonly RTI_ID = '(1694:0010)'; // RTI usb vendor id and product id
    public static readonly RTI_INPUT_DEVICE_LABEL = 'Motorola RTI';
    public static readonly RTI_OUTPUT_DEVICE_LABEL = 'Motorola RTI';
    private inputDeviceInterface: InputDeviceInterface;
    private outputDeviceInterface: OutputDeviceInterface;
    private readonly headsetAlertsEnabled$: Signal<boolean>;
    private headsetAlertOutputDeviceInterface: OutputDeviceInterface;
    private headsetIrrOutputDeviceInterface: OutputDeviceInterface;
    constructor(
        private store: Store,
        private mediaService: MediaService,
        private alertService: AlertService,
        private irrService: IrrService
    ) {
        this.headsetAlertsEnabled$ = toSignal(this.store.select(selectHeadsetAlertingEnabled));
        effect(() => this.headsetAlertOutputDeviceInterface ? this.headsetAlertOutputDeviceInterface.connected = this.headsetAlertsEnabled$() : this.headsetAlertsEnabled$());
    }

    public init() {
        console.debug('Initializing RTI Audio Service.');
        // prompt user for initial microphone access
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            // then find all media devices
            .then(() => navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    let rtiDevices = devices.filter((d) => d.label.includes(RtiAudioService.RTI_ID));
                    let input = rtiDevices.find((d) => d.kind === 'audioinput' && d.label.includes(RtiAudioService.RTI_INPUT_DEVICE_LABEL));
                    let output = devices.find((d) => d.kind === 'audiooutput' && d.label.includes(RtiAudioService.RTI_OUTPUT_DEVICE_LABEL));
                    return [input, output];
                })
            ).then(([input, output]) => {
            this.inputDeviceInterface = new InputDeviceInterface(input);
            this.inputDeviceInterface
                .init()
                .then(() => this.mediaService.setLocalMediaStream(this.inputDeviceInterface.mediaStream))
                .then(() => this.store.dispatch(getUserMediaSuccess()));
            this.outputDeviceInterface = new OutputDeviceInterface(output);
            this.outputDeviceInterface
                .init()
                .then(() => this.outputDeviceInterface.setRemoteMediaStream(this.mediaService.remoteMediaStream$.value));
            this.store
                .select(selectUsbAlertOutputToHeadsets)
                .subscribe((alertDevice) => {
                    this.headsetAlertOutputDeviceInterface?.destroy();
                    this.headsetAlertOutputDeviceInterface = undefined;
                    if (alertDevice) {
                        console.debug(`RTI: Sending Alert output to headsets`);
                        this.headsetAlertOutputDeviceInterface = new OutputDeviceInterface(output);
                        this.headsetAlertOutputDeviceInterface
                            .init()
                            .then(() => this.headsetAlertOutputDeviceInterface.setLocalMediaStreams([this.alertService.mediaStream]))
                            .then(() => (this.headsetAlertOutputDeviceInterface.connected = this.headsetAlertsEnabled$()));
                    }
                });
            this.store
                .select(selectUsbIrrOutputToHeadsets)
                .subscribe((irrDevice) => {
                    this.headsetIrrOutputDeviceInterface?.destroy();
                    this.headsetIrrOutputDeviceInterface = undefined;
                    if (irrDevice) {
                        console.debug(`RTI: Sending IRR output to headsets`);
                        this.headsetIrrOutputDeviceInterface = new OutputDeviceInterface(output);
                        this.headsetIrrOutputDeviceInterface.init().then(() => this.headsetIrrOutputDeviceInterface.setLocalMediaStreams([this.irrService.mediaStream]));
                    }
                });
        });
        this.mediaService.remoteMediaStream$.subscribe((remoteMediaStream) => this.outputDeviceInterface?.setRemoteMediaStream(remoteMediaStream));
    }

}
