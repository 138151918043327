/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

export function playAlertCondition(
    userMediaOutputDeviceId: string,
    alertMediaOutputDeviceId: string,
    isObserving: boolean,
    hasActiveVoiceCall: boolean,
    isExternalAlertDevice: boolean
) {
    console.log(
        `Checking if an alert can be played: ${isExternalAlertDevice || !hasActiveVoiceCall || isObserving || userMediaOutputDeviceId !== alertMediaOutputDeviceId} hasActiveVoiceCall:[${hasActiveVoiceCall}] isObserving:[${isObserving}] userMediaOutputDeviceId:[${userMediaOutputDeviceId}] alertMediaOutputDeviceId:[${alertMediaOutputDeviceId}] isExternalAlertDevice:[${isExternalAlertDevice}]`
    );
    return isExternalAlertDevice || !hasActiveVoiceCall || isObserving || userMediaOutputDeviceId !== alertMediaOutputDeviceId;
}
