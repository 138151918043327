import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => {
      let errorDisplay = document.querySelector('#initialization-error');
      errorDisplay.classList.add('show');
      if (!errorDisplay.textContent) {
          errorDisplay.textContent = err;
      }
      console.error(err);
  });
