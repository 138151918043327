/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { CallbackUtil } from '../../call/util/callback-util';

@Pipe({
    name: 'phoneDisplayFormat'
})
export class PhoneDisplayFormatPipe implements PipeTransform {
    transform(callback: string): string {
        let result = CallbackUtil.parseUserFromSipUri(callback);
        result = result === 'anonymous' ? 'Anonymous' : result;
        return isValidPhoneNumber(result, 'US') ? parsePhoneNumber(result, 'US').formatNational() : result;
    }
}
