/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpdateService } from './core/services/update.service';
import { ErrorToastInterceptor } from './interceptors/error-toast.interceptor';
import { SharedModule } from './shared/shared.module';

import { AppInitializer } from './core/util/app-initializer';
import { EnvironmentService } from './core/services/environment.service';
import { NotificationModule } from './notification/notification.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from '../environments/environment';
import {
    AppSpecificConfigToken,
    CommandCentralHeaderComponentsModule,
    HeaderComponentModule,
    IamAuthGrantConfigToken,
    UserAuthenticationModule
} from '@msi/commandcentral-common-header';
import { KeycloakBearerInterceptor } from './interceptors/keycloak-bearer-interceptor';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './core/window/directives/custom-overlay-container';
import { SessionGuardService } from './session-guard.service';
import { KeycloakOidcProviderService } from './core/services/keycloak-oidc-provider.service';

@NgModule({
    declarations: [AppComponent, UnauthorizedComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        HotkeyModule.forRoot(),
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 100, logOnly: true }),
        NotificationModule,
        // Security
        KeycloakAngularModule,
        ServiceWorkerModule.register('msi-service-worker.js'),
        UserAuthenticationModule,
        HeaderComponentModule,
        CommandCentralHeaderComponentsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [EnvironmentService, KeycloakService, KeycloakOidcProviderService, SessionGuardService],
            useFactory: AppInitializer
        },
        {
            provide: IamAuthGrantConfigToken,
            useValue: {
                scopes: ['CCAdmin'],
                clientId: 'ccch_calltaking_ui',
                singlePreprod: true,
                postLogoutRedirectUrl: window.location.origin
            }
        },
        {
            provide: AppSpecificConfigToken,
            useValue: {
                scopes: ['CCAdmin'],
                clientId: 'ccch_calltaking_ui',
                handleLegacyAdmin: false,
                developmentMode: !environment.production,
                singlePreprod: true,
                postLogoutRedirectUrl: window.location.origin
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorToastInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakBearerInterceptor,
            multi: true
        },
        { provide: OverlayContainer, useClass: CustomOverlayContainer }
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: RetryInterceptor,
        //     multi: true
        // }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    public constructor(protected updateService: UpdateService) {}
}
