/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */


import * as JsSIP from 'jssip';

export class MediaFunctions {

    public static parseSipUri(uri: string): JsSIP.URI {
        return JsSIP.URI.parse(uri);
    }

    public static isSipUri(uri: string) {
        return Boolean(MediaFunctions.parseSipUri(uri));
    }

    public static getHostFromUri(uri: string) {
        return MediaFunctions.parseSipUri(uri)?.host;
    }

    public static getSipUri(uri: string) {
        return JsSIP.URI.parse(`sip:${uri}`)?.toString();
    }

    public static getContactSipUri(uri: string) {
        let contact = JsSIP.URI.parse(`sip:${uri}`);
        contact?.setParam('transport', 'wss');
        return contact?.toString();
    }

    public static hasAgencySubdomain(sipUri: string, agencyId: string) {
        // Supposedly a valid URI host may end with '.' however the SIP library will fail to parse a SIP URI with a trailing '.'
        let dotTrimmedSipUri = sipUri.endsWith('.') ? sipUri.slice(0, -1) : sipUri;
        // agencyId is not guaranteed to be all lowercase nor is it guaranteed to not end with a '.'
        let dotTrimmedLowerCaseAgencyId = (agencyId.endsWith('.') ? agencyId.slice(0, -1) : agencyId).toLowerCase();
        const host = MediaFunctions.getHostFromUri(dotTrimmedSipUri);
        return host && host.length && host.endsWith(dotTrimmedLowerCaseAgencyId);
    }
}
