/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { HealthService } from './health.service';
import { HttpClient } from '@angular/common/http';
import { ServiceName } from '../../../assets/config/environment';

export class KeycloakHealthService extends HealthService {
    constructor(http: HttpClient, serviceUrls: string[], healthEndpoint: string, startingIndex = 0) {
        super(http, ServiceName.IDM, serviceUrls, healthEndpoint, startingIndex);
    }
}
