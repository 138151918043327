/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { EnvironmentService } from '../services/environment.service';
import { KeycloakService } from 'keycloak-angular';
import { SessionGuardService } from '../../session-guard.service';
import { IEnvironment } from '../../../assets/config/environment';
import { KeycloakOidcProviderService } from '../services/keycloak-oidc-provider.service';

export function AppInitializer(
    envService: EnvironmentService,
    keycloak: KeycloakService,
    oidcProviderService: KeycloakOidcProviderService,
    sessionGuardService: SessionGuardService
) {
    return () =>
        new Promise(async (resolve, reject) => {
            let config: IEnvironment;
            let KEYCLOAK_RETRY_INTERVAL = 5000;

            try {
                config = await envService.loadConfiguration();
            } catch (error) {
                console.error('err', error);
                reject('Failure to load client configuration.');
                return;
            }
            await sessionGuardService.canInitialize().catch((reason) => {
                reject(reason);
                return;
            });

            // oidcProviderService is used to override keycloak endpoints in order to use federation specific token refresh urls
            // this allows tokens to be refreshed during a federation outage
            // wait for initial keycloak health check to pass via initialized promise
            // in order to verify the initially selected federation url is reachable on keycloak.init
            await oidcProviderService.initialized;

            const attemptLogin = keycloak.init({
                config: {
                    url: config.AUTH_URL,
                    realm: config.AUTH_REALM,
                    clientId: config.AUTH_CLIENT_ID,
                    //@ts-ignore
                    oidcProvider: config.featureFlags?.ccAdminIntegrated ? undefined : oidcProviderService
                },
                initOptions: {
                    checkLoginIframe: true,
                    checkLoginIframeInterval: 5
                },
                enableBearerInterceptor: false,
                bearerExcludedUrls: [
                    'https://idm.pi.commandcentral.com',
                    'https://admin-api.pi.commandcentral.com',
                    'idm.imw.motorolasolutions.com',
                    'https://admin-api.usgov.commandcentral.com'
                ]
            });

            try {
                await attemptLogin;
                console.log('resolving keycloak...');
                oidcProviderService.resetStartingIndex();
                resolve('');
            } catch (err) {
                setKeycloakError(keycloak);
                console.error(`Error during initialization: ${JSON.stringify(err)}`);
                reject(err);
                setTimeout(async () => {
                    oidcProviderService.incrementStartingIndex();
                    window.location.reload();
                }, KEYCLOAK_RETRY_INTERVAL);
            }
        });
}

function setKeycloakError(keycloak: KeycloakService) {
    let initializationBlock = document.getElementById('initialization-error');
    initializationBlock.innerHTML =
        '<div id="auth-error-wrapper">' +
        '<div id="auth-error-inner-wrapper">' +
        '<svg width="128px" height="128px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" role="img" aria-label="error">\n' +
        '     <path d="M3.60167 21.0002H20.3983C21.5538 21.0002 22.2754 19.7487 21.6965 18.7487L12.8654 3.49506C12.4803 2.82977 11.5197 2.82977 11.1346 3.49506L2.30353 18.7487C1.72458 19.7487 2.44617 21.0002 3.60167 21.0002ZM10.5 10.0002C10.5 9.44795 10.9477 9.00023 11.5 9.00023H12.5C13.0523 9.00023 13.5 9.44795 13.5 10.0002V15.5002C13.5 15.7764 13.2761 16.0002 13 16.0002H11C10.7239 16.0002 10.5 15.7764 10.5 15.5002V10.0002ZM10.5 18.0002C10.5 17.4479 10.9477 17.0002 11.5 17.0002H12.5C13.0523 17.0002 13.5 17.4479 13.5 18.0002C13.5 18.5525 13.0523 19.0002 12.5 19.0002H11.5C10.9477 19.0002 10.5 18.5525 10.5 18.0002Z"></path>\n' +
        '</svg>' +
        '<div id="auth-error-text">' +
        '<div id="auth-error-title">Sign in failed</div>' +
        '<p>The VESTA NXT sign in process has encountered a technical error. A recovery process has been initiated and may take a few moments to resolve the issue.</p>' +
        `<button id="recovery-signout-btn" class="msi-btn fail-recovery">Back to sign in</button>` +
        '</div>' +
        '</div>' +
        '</div>';
    (<HTMLButtonElement>initializationBlock.querySelector('#recovery-signout-btn')).onclick = () => keycloak.logout();
}
